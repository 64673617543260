import { useVault } from "@/hooks/vault/useVault";
import { useChain } from "@/hooks/Web3ModalProvider";
import { Token } from "@/lib/entities/token.entity";
import { updateChainId } from "@/redux/chain.state";
import { useAppDispatch } from "@/redux/store";
import { updateMultipleTokens } from "@/redux/whitelisted-token.state";
import { useRouter } from "next/router";
import { FC, useCallback, useEffect } from "react";

export const OnLoadModule: FC = () => {
  const dispatch = useAppDispatch();
  const vaultQueryData = useVault();
  const { desiredChain } = useChain();

  const router = useRouter();

  const updateTokens = useCallback(() => {
    // Extract token list
    const { vaults } = vaultQueryData;
    const tokens = Object.values(vaults)
      .filter((vault) => !vault.isDummyData)
      .map((vault) => {
        return Token.fromVaultInfo(vault);
      })
      .reduce((acc, [token, pToken]) => [...acc, token, pToken], [] as Token[]);

    // Update tokens in redux
    dispatch(
      updateMultipleTokens([
        desiredChain.nativeToken,
        desiredChain.wrappedNativeToken,
        ...tokens,
      ])
    );
  }, [vaultQueryData, desiredChain]);

  useEffect(() => {
    if (vaultQueryData.isPlaceholderData) return;
    updateTokens();
  }, [vaultQueryData]);

  useEffect(() => {
    const desiredChainId = router.query.chainId as string;
    dispatch(updateChainId(Number(desiredChainId ?? NaN)));
  }, [router?.query?.chainId]);

  return <></>;
};
