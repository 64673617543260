import { AppNumber } from "@/lib/providers/math/app-number.provider";
import {
  TextProps,
  Tooltip,
  Text,
  TooltipProps,
  Skeleton,
} from "@chakra-ui/react";
import { FC } from "react";

type Props = TextProps & {
  toolTipProps?: TooltipProps;
  isLoading?: boolean;
  value: AppNumber;
  content?: string;
};

export const NumberText: FC<Props> = ({
  value,
  toolTipProps,
  isLoading,
  content,
  ...props
}) => {
  const realValue = value.getValue().toString();
  const roundedValue = value.getDisplayedString();
  const isHasTooltip = realValue !== roundedValue;

  return (
    <Tooltip label={isHasTooltip ? realValue : ""} {...toolTipProps}>
      <Skeleton isLoaded={!isLoading}>
        <Text {...props}>
          {roundedValue}
          {content && ` ${content}`}
        </Text>
      </Skeleton>
    </Tooltip>
  );
};
