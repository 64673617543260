import { DUMMY_VAULTS } from "@/lib/constants/vault";
import { VaultInfo } from "@/lib/entities/vault.entity";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import {
  getSingleVaultQuery,
  getVaultsQuery,
  updateMultipleVault,
  updateSingleVault,
} from "@/redux/vault.state";
import { useEffect } from "react";
import { useQueryFetch } from "@/hooks/api/useQueryFetch";

export const useVault = () => {
  const dispatch = useAppDispatch();
  const vaults = useAppSelector((state) => getVaultsQuery(state));

  const queryFetch = useQueryFetch();
  const queryData = queryFetch("vault_list", {
    queryParams: { orderBy: "tvl.tvl", orderDirection: "desc" },
    queryOptions: {
      placeholderData: DUMMY_VAULTS,
      refetchInterval: 1000 * 60 * 5,
    },
  });

  useEffect(() => {
    const data =
      queryData?.data?.map((vault) => VaultInfo.fromEntity(vault)) || [];
    const payload = { data, reset: !queryData.isPlaceholderData };
    dispatch(updateMultipleVault(payload));
  }, [queryData.data]);

  return {
    vaults,
    ...queryData,
  };
};

export const useGetHighestAprVault = () => {
  const vaults = useAppSelector((state) => getVaultsQuery(state));
  return Object.values(vaults).reduce((acc, vault) => {
    if (!acc) return vault;
    const a = vault.apr.forwardAPR.composite.v3OracleStratRatioAPY ?? 0;
    const b = acc.apr.forwardAPR.composite.v3OracleStratRatioAPY ?? 0;
    if (a > b) return vault;
    return acc;
  }, null);
};

export const useGetVault = (vaultAddress: string) => {
  const dispatch = useAppDispatch();
  const vault = useAppSelector((state) =>
    getSingleVaultQuery(state, vaultAddress)
  );

  const queryFetch = useQueryFetch();
  const queryData = queryFetch("vault_list", {
    queryOptions: {
      placeholderData: DUMMY_VAULTS,
      refetchInterval: 1000 * 60 * 5,
    },
  });

  useEffect(() => {
    if (queryData.isPlaceholderData) return;
    const vault = queryData.data.find(
      (vault) => vault.address === vaultAddress
    );

    if (!vault) return;
    dispatch(updateSingleVault(VaultInfo.fromEntity(vault)));
  }, [queryData.data]);

  return {
    vault,
    isLoading:
      queryData.isLoading || queryData.isPlaceholderData || queryData.isPending,
  };
};
